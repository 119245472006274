import React from 'react'
import PageLayout from '../components/PageLayout';
import { graphql, Link } from "gatsby";
import GalleryItem from '../components/Gallery/GalleryItem';
import GalleryGrid from '../components/Gallery/GalleryGrid';
import { GatsbyImgWhiteMask } from '../components/ImgComponents/imgComponents';


function Workshop({ data }) {
  const workshopList = data.allWorkshops.edges
  .filter((edge) => {
    return !edge.node.frontmatter.hidden;
  })
  .map((edge) => {
    const { node } = edge;
    const { title, coverImage, id } = node.frontmatter;

    return (
      <Link to={`/workshop/${id}`} key={id}>
        <GalleryItem
          imgComponent={<GatsbyImgWhiteMask
            fluid={coverImage.childImageSharp.fluid
          }/>}
          title={title}
          whiteMask
        />
      </Link>
    );
  });
  return (
    <PageLayout title="Workshop" uppercaseTitle titleAnim>
      <GalleryGrid>
        {workshopList}
      </GalleryGrid>
    </PageLayout>
  );
}

export default Workshop;

export const query = graphql`
  query {
    allWorkshops: allMdx(
      sort: { order: DESC, fields: [frontmatter___time] },
      filter: {fileAbsolutePath: {regex: "/(/workshop/)/"}, frontmatter: {id: {regex: "/^((?!side|footer).)*$/"}}}
    ) {
      edges {
        node{
          frontmatter {
            id
            title
            hidden
            coverImage {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;